.jan042016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/brick.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.feb112016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/repot-plant.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.feb282016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/writing.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.may182016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/kid-fly.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.may242016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/conference.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.may262016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/fb.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.may272016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/mom-roar.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.may312016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/snow-boot.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jun102016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/jail.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sept022016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/hurricane-back.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sept172016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/lawn.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dec312016 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2016/lounging.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}