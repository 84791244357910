.footer-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    bottom: 0; left: 0; right: 0;
    background: url(../../public/images/vert-st.png);
    background-repeat: no-repeat;
    background-size: 100vw;
}

.footer-left-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Merienda', cursive;
    font-size: smaller;
}

.footer-h-link {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.footer-center-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.social {
    height: 30px;
    padding-inline: calc(0px + 1.5625vw);
}

.footer-right-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: 'Merienda', cursive;
    font-size: smaller;
}
/* ------------------------------ */


/* POP-UP HINTS */
/* Hide the hint by default */
[data-hint]::before {
    content: "";
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
  }
  
  /* Show the hint on hover or focus */
  [data-hint]:hover::before, [data-hint]:focus::before {
    content: attr(data-hint);
    position: absolute;
    /* top: -20%; */
    /* left: 46%; */
    transform: translateY(-120%);
    background-color: black;
    color: white;
    padding: 0.5em;
    border-radius: 0.25em;
    font-size: 0.7em;
    font-family: Arial, Helvetica, sans-serif;
    opacity: 1;
    visibility: visible;
  }
/* ------------------------------ */