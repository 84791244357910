
.container {
    position: relative;
    width: 320px;
    margin: auto;
    perspective: 2000px;
}
    
.carousel {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    animation: rotate360 60s infinite forwards linear;
}
    
.image {
    position: absolute;
    width: 300px;
    height: 187px;
    top: 20px;
    left: 10px;
    right: 10px;
    background-size: cover;
    display: flex;
    border-radius: 10px;
    border: 1px solid white;
}

span {
    margin: auto; 
    font-size: 2rem;
}

@keyframes rotate360 {
    from {
    transform: rotateY(0deg);
    }
    to {
    transform: rotateY(-360deg);
    }
}

.image:nth-child(1) {
    background-image: url(../../../../public/images/2015/carousel-img-1.jpg);
    transform: rotateY(0deg) translateZ(430px);
}

.image:nth-child(2) {
    background-image: url(../../../../public/images/2015/carousel-img-2.jpg);
    transform: rotateY(40deg) translateZ(430px);
}

.image:nth-child(3) {
    background-image: url(../../../../public/images/2015/carousel-img-3.jpg);
    transform: rotateY(80deg) translateZ(430px);
}

.image:nth-child(4) {
    background-image: url(../../../../public/images/2015/carousel-img-4.jpg);
    transform: rotateY(120deg) translateZ(430px);
}

.image:nth-child(5) {
    background-image: url(../../../../public/images/2015/carousel-img-5.jpg);
    transform: rotateY(160deg) translateZ(430px);
}

.image:nth-child(6) {
    background-image: url(../../../../public/images/2015/carousel-img-6.jpg);
    transform: rotateY(200deg) translateZ(430px);
}

.image:nth-child(7) {
    background-image: url(../../../../public/images/2015/carousel-img-7.jpg);
    transform: rotateY(240deg) translateZ(430px);
}

.image:nth-child(8) {
    background-image: url(../../../../public/images/2015/carousel-img-8.jpg);
    transform: rotateY(280deg) translateZ(430px);
}

.image:nth-child(9) {
    background-image: url(../../../../public/images/2015/carousel-img-9.jpg);
    transform: rotateY(320deg) translateZ(430px);
}