a {
    color: black;
    text-decoration: none;
}

.main-sec {
    background-image: url(../../public/images/vert-st.png);
    background-repeat: repeat;
    background-size: 100vw;
    padding-bottom: 70px;
}

.main-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.main-flex-left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: calc(10px + 1.5625vw);
}

.head-pic {
    float: left;
    width: calc(350px + 1.5625vw);
    border-radius: 5%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
    margin-right: -100px;
    z-index: 9;
}

.main-flex-right {
    display: flex;
    flex-direction: row;
    padding: calc(10px + 1.5625vw);
}

.main-flex-right-sub-1 {
    display: flex;
    flex-direction: column;
    border-radius: 0px 200px 0px 200px;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url(../../public/images/texture-paper1.png);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    font-family:Arial, Helvetica, sans-serif;
    margin-bottom: -300px;
    margin-left: -100px;
    padding-left: 200px;
    padding-right: 100px;
    width: 50vw;
}

.back-container {
    display: flex;
    justify-content: center;
}

.back-btn {
    text-decoration: none;
    text-shadow: 0px 0px 5px white;
}

.main-flex-bottom {
    height: 200px;
}

.p {
    margin: 10px;
}


/*Mobile*/
/* @media (max-width: 768px) {
    .main-container {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .head-pic {
        max-width: 70vw;
    }
    .main-flex-right {
        max-width: 90vw;
    }
} */