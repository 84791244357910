.main-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

.arch-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .4);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    margin-left: 100px;
    margin-right: 100px;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
  }

.arch-grid-indiv {
    margin: 5px;
}

.p-img-container {
    display: flex;
    flex-direction: row;
    justify-content:space-around;
}

.p-imgs {
    width: 400px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .4);
    border: 1px solid rgba(255, 255, 255, 0.4);
}