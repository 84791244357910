.sept212009 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2009/muddy-road.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sept282009 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2009/sunset.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}