.jan042013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/cake.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jan112013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/ghostpaper.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.mar182013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/mag-book.jpg);
    background-size: 100vw;
    background-position: center;
    background-repeat: no-repeat;
}

.apr012013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/heart.jpg);
    background-size: 100vw;
    background-position: center;
    background-repeat: no-repeat;
}

.may012013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/garden.jpg);
    background-size: 100vw;
    background-position: center;
    background-repeat: no-repeat;
}

.may132013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/bookstore.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jul162013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/book13.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jul222013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/reunion.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jul302013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/book13.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.aug142013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/clothingrack.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sept192013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/book13.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.oct152013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/forsyth-park.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.oct222013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/ufo.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.nov252013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/bibleleaf.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dec022013 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/books-bottom.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}