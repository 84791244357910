.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(/public/images/mail-pile.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.email-btn {
  display: flex;
  justify-content: center;
  width:20vw;
}


/* .cont-sec {
    height: 70vh
}

.form-container {
    width: 350px;
    padding: 20px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: black;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(6px);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .4);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.ff {
  border: 1px solid black;
}

  input,
  textarea,
  button {
    padding: 9px;
    border: none;
    border-radius: 5px;
    outline: none;
  }
  
  textarea {
    resize: vertical;
  }
  
  button {
    cursor: pointer;
    font-weight: 500;
    background-color: rgb(127, 255, 240);
  }
  
  button:hover {
    background-color: rgb(69, 183, 240);
  }
  
  button:active {
    background-color: rgb(149, 241, 210);
  }
  
  i {
    color: rgb(59, 59, 59);
    margin-left: 10px;
  } */