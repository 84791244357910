.jan312014 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2014/ybroad.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.mar102014 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2014/pig-in-stroller.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.apr242014 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2014/football-field.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jul282014 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2014/crowded-beach.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sept082014 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2014/woman-dog-trip.jpg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.sept092014 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2013/book13.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dec162014 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2014/school-desk.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dec262014 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2014/monkey-bread.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}