@import url('https://fonts.googleapis.com/css2?family=Merienda&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&family=Tilt+Neon&display=swap');


/* HAMBURGER MENU */
* {
    padding: 0;
    margin: 0;
    /* box-sizing: border-box; */
}

li {
    list-style: none;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../public/images/inksplat.png);
    background-repeat: no-repeat;
    background-position:center;
    background-size: 100%;
}

.logo {
    width: 50vw;
    max-width: 600px;
    min-width: 275px;
}

.nav-bar {
    position: relative;
    /* min-height: 70px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 24px; */
    background-color: white
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    z-index: 2;
}

.nav-link {
    transition: 0.7s ease;
    font-size: 1.2em;
    font-weight: bolder;
    text-decoration: none;
    color: rgb(167, 99, 106);
    font-family: 'Calligraffitti', cursive;
    padding: 0 24px;
}

.nav-link:hover {
    color: dodgerblue;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

@media(max-width:768px) {
    .hamburger {
        display: block;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }
    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu {
       position: fixed;
       left: 0;
       top: 20vh;
       /* top: 70px; */
       gap: 0;
       flex-direction: column;
       background-color: #262626;
       width: 100%;
       text-align: center;
       transition: 0.4s;
       transform: translateX(-100%);
    }

    .nav-menu.active {
        transform: translateX(0);
        left: 0;
    }

    .nav-item {
        margin: 5px 0;
    }

    .bar {
        background-color: black;
    }

    .parallax {
        display: flex;
        width: 100vw;
    }
}



/* TITLE HOME LINK */
/* .links-h {
    text-shadow: 2px 2px black;
    text-decoration: none;
    color: rgb(167, 99, 106);
    font-family: 'Calligraffitti', cursive;
}

.links-h:link {
    text-shadow: 2px 2px black;
    text-decoration: none;
    color: rgb(167, 99, 106);
    font-family: 'Calligraffitti', cursive;
}

.links-h:visited {
    text-shadow: 2px 2px black;
    text-decoration: none;
    color: rgb(167, 99, 106);
    font-family: 'Calligraffitti', cursive;
}

.links-h:hover {
    color: rgb(105, 171, 185);
    transition: all .2s ease-out;
}


.nav-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
}

.links {
    margin-bottom: 0px;
    font-size: larger;
    text-shadow: 1px 1px black;
    padding-left: 100px;
    padding-right: 100px;
}

.links:link {
    text-decoration: none;
    color: rgb(105, 171, 185);
    font-family: 'Calligraffitti', cursive;
}

.links:visited {
    text-decoration: none;
    color: rgb(105, 171, 185);
    font-family: 'Calligraffitti', cursive;
}

.links:hover {
    color: rgb(167, 99, 106);
    transition: all .2s ease-out;
}


.header {
    position:relative;
    text-align:center;
    background-image: url(../../public/images/inksplat.png);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}

.inner-header-1 {
    width:100%;
    margin: 0;
    padding: 0;
    font-size: calc(24px + 1.5625vw);
    color: rgb(0, 0, 0);
    font-family: 'Calligraffitti', cursive;
    word-spacing: 20px;
}

.inner-header-5 {
    width:100%;
    margin: 0;
    padding: 0;
    font-size: calc(20px + 0.3vw);
    color: rgb(0, 0, 0);
    font-family: 'Calligraffitti', cursive;
    word-spacing: 20px;
} */




/* WAVE ANIMATED BAR */
.waves {
    position:relative;
    width: 100%;
    /* height:15vh; */
    /* margin-bottom:-7px; */
     /*Fix for safari gap*/
    min-height:20px;
    max-height:40px;
    background-image: url(../../public/images/pastel-vert.jpg);
    background-size: contain;
    z-index: 1;
}


/* Animation */
.parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 15s;
}
.parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 7s;
}
.parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 10s;
}
.parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}
@keyframes move-forever {
    0% {
        transform: translate3d(-90px,0,0);
    }
    100% { 
        transform: translate3d(85px,0,0);
    }
}






/*Mobile*/
@media (max-width: 768px) {
    .waves {
        height:20px;
        min-height:10px;
        z-index: 1;
  }
    h1 {
        font-size:24px;
    }
}