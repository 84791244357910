.jan292010 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2010/snowstorm.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.may012010 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2010/flowers.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jul112010 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2010/campfire.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.nov182010 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2010/ramen.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.nov252010 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2010/cornucopia.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}