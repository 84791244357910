.jan262015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/walking.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jan052015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/procrastinator.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jan072015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/gold-star.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jan122015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/towels.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.feb032015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/coolio.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.feb132015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/sky.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.mar032015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/methblue.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.mar092015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/cursive.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.apr292015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/lavender.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jun122015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/alligator-boots.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jul062015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/bodyclock.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.aug182015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/cabin.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.oct112015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/hindu-temple.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.nov062015 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2015/rearview-mirror.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}