.feb222011 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/treadmill.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.apr132011 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/glass-slippers.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.apr142011 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/country.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jun212011 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/dia-sec.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jun222011a {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/first-class.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jun222011b {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/lav.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.jun262011 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/tree.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sept282011 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/tp-pile.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.oct192011 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/fall.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dec212011 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2011/nails.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}