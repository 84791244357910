.jan172012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/cheez-it.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.feb022012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/clearance.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.feb132012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/writing2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.feb212012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/notebook.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
}

.mar232012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/ping-pong.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.mar262012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/coffee-beans.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.apr162012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/bible.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.apr192012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/ab-dance.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.jun112012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/hair.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.aug022012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/chik-fil-a-cows.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.aug062012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/pen.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.aug232012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/tel-aviv.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.dec122012 {
    background-image: linear-gradient(to top, rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(../../../../public/images/2012/salt.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}